[data-v-2a74b4c6] .el-step__title {
  cursor: pointer;
}
.ticketMain[data-v-2a74b4c6] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ticketLeft[data-v-2a74b4c6] {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}
.ticketLeft[data-v-2a74b4c6] .head-layout {
  height: 46px;
  line-height: 46px;
}
.ticketRight[data-v-2a74b4c6] .head-layout {
  height: 46px;
  line-height: 46px;
}
.ticketStep[data-v-2a74b4c6] {
  width: calc(100% - 40px);
  height: 300px;
  padding: 20px;
}
.ticketRight[data-v-2a74b4c6] {
  width: calc(100% - 146px);
  height: 100%;
}
.ticketContent[data-v-2a74b4c6] {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  scrollbar-width: none;
}
.ticketHead[data-v-2a74b4c6] {
  position: relative;
}
.ticketHeadBtn[data-v-2a74b4c6] {
  position: absolute;
  top: 7px;
  left: 150px;
}
.ticketHeadCode[data-v-2a74b4c6] {
  position: absolute;
  top: 7px;
  left: 400px;
}
.ticketForm[data-v-2a74b4c6] {
  padding: 12px;
  background-color: #FFFFFF;
}
.ticketGas[data-v-2a74b4c6] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.gasTable[data-v-2a74b4c6] {
  padding: 12px;
}
.ticketSecure[data-v-2a74b4c6] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.secureTable[data-v-2a74b4c6] {
  padding: 12px;
}
.ticketDisclose[data-v-2a74b4c6] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.ticketDisclose-no-margin[data-v-2a74b4c6] {
  background-color: #FFFFFF;
}
.signatureImg[data-v-2a74b4c6] {
  width: 70px;
  height: 32px;
  margin: auto;
}
[data-v-2a74b4c6] .el-textarea .el-input__count {
  height: 20px !important;
}